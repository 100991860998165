import React from 'react';
import { Navigate } from 'react-router-dom';
import { TOKEN_KEY } from './constants/key.const';

const AuthGuard = ({ children }) => {
  const isAuthenticated = !!sessionStorage.getItem(TOKEN_KEY); // Replace TOKEN_KEY with your actual key
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default AuthGuard;
